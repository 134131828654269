import React from 'react';
import { H2 } from '@dnb/eufemia';
import { Button } from '@dnb/eufemia';
import { InfoCard } from '@dnb/eufemia';
import { H1, P } from '@dnb/eufemia/elements';
import Head from '@dnb/helmet';
import { navigate } from 'gatsby';

function PageIndex() {
  return (
    <>
      <Head>
        <title>Web Framework demos</title>
      </Head>
      <H1 className="simpleHeaderTitle" bottom="1rem" top="3rem">
        Error Boundary demos
      </H1>
      <P bottom="2rem">
        These pages will demonstrate failing code in production and how you can
        use the library @dnb/error-boundary to catch them.
      </P>
      <H2 bottom="0.5rem">ErrorBoundary</H2>
      <P>
        The component is used to catch errors occuring in a small part of your
        code, such as in a component.
      </P>
      <Button
        onClick={() => navigate('/error-boundary-demo/error-boundary')}
        text="ErrorBoundary demo"
        variant="secondary"
        top="1rem"
        icon="chevron_right"
      />

      <H2 bottom="0.5rem">useErrorHandler</H2>
      <P>
        ErrorBoundary catches errors in lifecycle methods, in render and in the
        constructur of a React child component. It does not by default catch
        errors in event handlers and async code. You can handle those errors
        with the hook useErrorHandler(), which will throw an error to the
        closest ErrorBoundary wrapping the component.
      </P>
      <Button
        onClick={() => navigate('/error-boundary-demo/use-error-handler')}
        text="useErrorHandler demo"
        variant="secondary"
        top="1rem"
        icon="chevron_right"
      />

      <H2 bottom="0.5rem">GlobalErrorBoundary</H2>
      <P>
        The component wraps your app by default from @dnb/root-app and will
        catch all render errors occuring which you haven't wrapped with{' '}
        {`<ErrorBoundary>`}.
      </P>
      <Button
        onClick={() => navigate('/error-boundary-demo/global-error-boundary')}
        text="GlobalErrorBoundary demo"
        variant="secondary"
        top="1rem"
        bottom="2rem"
        icon="chevron_right"
      />
      <InfoCard
        text="In development: Remember to click away the error handler first displayed
        by Gatsby to see what it will look like in production."
        bottom="1rem"
      />
    </>
  );
}

export default PageIndex;
